import { Store } from "~client/models";
import {AuthContext} from "~client/context/Auth";
import { useContext, useEffect, useState } from "react";
import { gql, useClient, useQuery } from "urql";

import { GET_ALL_STORES } from "~client/AllGraphQLQueries";
import { deserializeFromGraphQL } from "~client/DecORM/utils/DataConversion";

export default function useStores():Store[]{

  console.log("at useAPIKeys")

  const auth = useContext(AuthContext);

  console.log(auth);

  let pause = false;

  if (!auth.isAuthInitialized){
      pause = true;
  }

  console.log("pause");
  console.log(pause);

  const [stores] = useQuery({ query: GET_ALL_STORES, pause, requestPolicy:"cache-and-network"  }, )

  console.log(stores);

  let allStores = stores.data?.getAllStores || [];

  return deserializeFromGraphQL(allStores); //stores?.data?.getAllStores || [];
}