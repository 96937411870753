//GENERATED AS COPY OF ../../DecORM/utils/TimeZonesEnum.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.

export enum TimeZones {
    "America/New_York" = 0,
}

export enum GraphQLTimeZones {
    AMERICA_NEW_YORK = 0,
}

export const DaysOfWeekList = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function getLocalExcelDate(): number{
    return 25569.0 + (new Date(new Date().toDateString() + " UTC").getTime() / 86400000)
}

export function convertLocalDateToExcel(date: Date): number{
    if (!date) date = new Date();
    return 25569.0 + ( new Date(date.toDateString() + " UTC").getTime() / 86400000)
}

export function convertDateToExcel(date?: Date, timeZone?: TimeZones): number {

    console.log("convert date to excel2");
    console.log(new Date().toDateString());
    console.log(new Date().getTime());
    console.log(new Date(new Date().toDateString() + " UTC").getTime());

    //console.log(Date.parse(new Date().toDateString()));

    if (!date){ date = new Date( new Date().toDateString() + " UTC")} //date = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) } 
    if (timeZone != null && timeZone != undefined) date = convertDateToTimeZone(date, timeZone);
    //let converted = 25569.0 + ((date.getTime() - (date.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))
    let converted = 25569.0 + (date.getTime() / (1000 * 60 * 60 * 24))
    return Math.floor(converted);
}

export function convertExcelToDate(date: number, timeZone?: TimeZones): Date {
    let date_info = new Date((Math.floor(date) - 25569) * 86400000);
    if (timeZone) date_info = convertDateToTimeZone(date_info, timeZone);
    return date_info
}

export function convertExcelToRelativeDate(date: number, timeZone?: TimeZones): string {
    let date_info = convertExcelToDate(date, timeZone);
    let now = getLocalExcelDate(); //convertDateToExcel();

    let diff = date - now;

    

    if (diff == 0) return "Today"; //start = "Today";
    if (diff == 1) return "Tomorrow"; //start =  "Tomorrow";
    if (diff == -1) return "Yesterday"; //start = "Yesterday";

    let start = DaysOfWeekList[date_info.getUTCDay()];

    return start + " " + (date_info.getUTCMonth() + 1) + "/" + date_info.getUTCDate() + "/" + date_info.getUTCFullYear().toString().slice(-2) ;

    if (diff >= 2 && diff <= 6) {
        return DaysOfWeekList[date_info.getUTCDay()] //+ d.getUTCDate(); ;
    }else {

        let last = "th";
        let utcDate = date_info.getUTCDate();
        if (utcDate == 1 || utcDate == 21 || utcDate == 31) {
            last = "st";
        } else if (utcDate == 2 || utcDate == 22) {
            last = "nd";
        } else if (utcDate == 3 || utcDate == 23) {
            last = "rd";
        }

        return DaysOfWeekList[date_info.getUTCDay()] + " the " + date_info.getUTCDate() + last;
    }


    
    
    //return convertDateToRelativeString(date_info);
}




export function convertDateToDaysSince1970(date?: Date | null | undefined, timeZone?: TimeZones): number {

    return convertDateToExcel(date, timeZone);

    if (date == null || date == undefined) date = new Date();
    if (timeZone != null && timeZone != undefined) date = convertDateToTimeZone(date, timeZone);
    return Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
}

export function convertDaysSince1970ToDate(days: number, timeZone?: TimeZones): Date {
    //if (date == null || date == undefined) date = new Date();
    //if (timeZone != null && timeZone != undefined) date = convertDateToTimeZone(date,timeZone);

    return convertExcelToDate(days, timeZone);

    return new Date(days * (1000 * 60 * 60 * 24));

    //  return Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
}

/**Takes a Date object and a timeZone int enum as input, returns a date converted to that time zone.*/
export function convertDateToTimeZone(date: Date | null | undefined, timeZone: TimeZones): Date {
    if (date == null || date == undefined) date = new Date();
    return new Date(date.toLocaleString('en-US', { timeZone: TimeZones[timeZone] }));
}

/**Takes a date and optionally a timezone as input. Returns yyyy-mm-dd */
export function convertDateToString(date?: Date, timeZone?: TimeZones): string {
    if (date == null || date == undefined) date = new Date();
    if (timeZone != null && timeZone != undefined) date = convertDateToTimeZone(date, timeZone);
    return date.getUTCFullYear + "-" + date.getUTCMonth + "-" + date.getUTCDay;
}

