//GENERATED AS COPY OF ../../DecORM/utils/DataConversion.ts. DO NOT EDIT THIS COPY DIRECTLY. EDIT THE ORIGINAL FILE INSTEAD.

import { instanceToPlain, plainToInstance } from "class-transformer";
import * as ALLMODELS from "~client/models";
import { Client, PromisifiedSource, useQuery, UseQueryResponse, UseQueryState } from "urql";
import { useMemo } from "react";

export function serializeToGraphQL(item: any, { }) {
    return instanceToPlain(item, {})
}

export function deserializeFromGraphQL(item: any) {
    console.log("at deserializeFromGraphQL")
    console.log(item);

    if (item == null) return null;
    if (item == undefined) return undefined;

    let typename = item?.__typename;

    if (Array.isArray(item)) {
        if (item.length === 0) {
            return []
        }
        typename = item[0]?.__typename;

        if (!typename){
            throw new Error("No typename found in item, you must have forgot to add __typename in your query.")
        }

    }else if (!typename){
        throw new Error("No typename found in item, you must have forgot to add __typename in your query.")
    }

    console.log(typename);
    console.log(ALLMODELS[typename]);

    let ret = plainToInstance(ALLMODELS[typename], item, {})

    if (Array.isArray(ret)) {
        return ret.filter((item) => !item?.deleted)
    }else{
        if (ret?.deleted) return null;
        return ret;
    }

}

export function deserializeGraphQLMap(data){

    if (data == null) return null;
    if (data == undefined) return undefined;

    let output = {};

    for (let [key, value] of Object.entries(data)) {
        output[key] = deserializeFromGraphQL(value);
    }

    return output;

}

export interface UseGraphQLQueryResponse extends UseQueryState{

reExecuteQuery:any;

}

export function useGraphQLQuery({query, variables=null, pause = false, requestPolicy="cache-and-network" }:{query: any, variables?: any, pause?: boolean, requestPolicy?: "cache-and-network" | "cache-first" | "cache-only" | "network-only"}):UseGraphQLQueryResponse {

    let queryInput: any = {query, pause, requestPolicy}
    if (variables) queryInput.variables = variables;

    const [urqlQuery,reExecuteQuery] = useQuery(queryInput);
    
    const data = useMemo(()=>deserializeGraphQLMap(urqlQuery.data),[urqlQuery.data])

    return {...urqlQuery, reExecuteQuery, data}

}

export async function executeGraphQLMutation({client, query, variables=null}:{query: any, variables?: any, client:Client}):Promise<{data?:any, error?:any, extensions?:any, operation?:any, validationErrors?:Record<string,string[]>}>
{
    let res = await client.mutation(query, variables).toPromise();

    let anyValidationErrors = false;
    let validationErrors = {};

    for (let err of res?.error?.graphQLErrors || []) {
//@ts-ignore
        for (let verr of err?.extensions?.validationErrors || []) {
            validationErrors[verr?.property] = Object.values(verr?.constraints) ;
            anyValidationErrors = true;
        }
    }

    console.log("at executeGraphQLMutation res");
    console.log(res);

    let output = {...res, validationErrors:undefined};

    if (anyValidationErrors) {
        output = {...output, validationErrors: validationErrors};
    }

    console.log("outp");
    console.log(output);

    return output;
}

