import { Resolvers, Roles } from "~client/configuration";
import { AuthContext } from "~client/context/Auth";
import { useContext } from "react";
import { ro } from "date-fns/locale";

type RenderIfAuthorizedInput = { children: any, resolver?: Resolvers, resolvers?: Resolvers[], role?: Roles, roles?: Roles[] } & ({ resolver: Resolvers } | { resolvers: Resolvers[] } | { role: Roles } | { roles: Roles[] });

export function RenderIfAuthorized({ children, resolver, resolvers = [], role, roles = [] }: RenderIfAuthorizedInput) {
    if (resolver) {
        resolvers = [resolver];
    }
    if (role) {
        roles = [role];
    }

    const authContext = useContext(AuthContext);
    let found = false;
    for (let resolver of resolvers) {
        if (authContext?.tenantUser?.resolversSet.has(resolver)) {
            found = true;
            break;
        }
    }

    if (!found && roles.length) {
        for (let role of roles) {
            if (authContext?.tenantUser?.rolesSet.has(role)) {
                found = true;
                break;
            }
        }
    }

    if (found) {
        return children;
    }
    return null;
}