import React, { useContext, useEffect, Fragment, useState, useMemo } from 'react';
import { Nav, Navbar, Row, Col, Collapse } from 'react-bootstrap';
import Flex from '~client/components/common/Flex';
import { gql, useQuery } from 'urql';
import { AuthContext } from "~client/context/Auth"; //"modules/auth/AuthWrapper" //'utilities/Authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { isJsxElement, StringMappingType } from 'typescript';

import { faUserCircle, faUserCog, faDraftingCompass, faClipboardList, faWalking, faEnvelope, faStore, faUsers, faCalendar, faDumpsterFire, faShippingFast, faMap, faMoneyCheckAlt, faSun, faMoon, faCheck, faArrowAltCircleRight, faArrowsAlt, faRedo, faTimes } from '@fortawesome/free-solid-svg-icons';


import { Resolvers, Roles } from '~client/configuration';
var retryOnce = false;



export const NavbarCollapseMenu = ({ icon, iconSize, badges, title, children, menu, liClassName = "" }: { liClassName?: string, badges: { type: string, text: string }[], icon: string, iconSize: string | null, title: string, children: any, menu: any }) => {

    let open = false;
    if (menu.openMenu == title) {
        open = true;
    }

    return (
        <Nav.Item as="li" className={liClassName}>
            <Nav.Link
                aria-expanded={open}
                onClick={() => {

                    if (menu.openMenu == title) {
                        menu.setOpenMenu("")
                    } else {
                        menu.setOpenMenu(title);
                    }

                    //Open or close
                }}
                className="dropdown-indicator cursor-pointer"//text-500 if disabled route
            >
                <Flex alignItems="center">
                    {icon && (
                        <span className="nav-link-icon" >
                            <FontAwesomeIcon style={{ marginRight: 5, paddingBottom:2 }} icon={icon} size={iconSize} />
                        </span>
                    )}

                    <span className="nav-link-text ps-1">{title}</span>
                    {badges && badges.map((badge, index) => {
                        return (
                            <SoftBadge key={index} pill bg={badge.type} className="ms-2">
                                {badge.text}
                            </SoftBadge>
                        )
                    })}
                </Flex>

            </Nav.Link>
            <Collapse in={open}>
                <div>
                    <Nav style={{ marginLeft: 20 }} className="flex-column nav" as="ul">
                        {/*<NavbarVerticalMenu routes={route.children} />*/}

                        {children}

                    </Nav>
                </div>
            </Collapse>
        </Nav.Item>
    )
}



const NavbarItemLink = ({ icon, iconSize, badges, title, href, extraElement = null }: { extraElement: any, badges: { type: string, text: string }[], icon: string, title: string, href: string, iconSize: string | null }) => {

    return (
        <Nav.Item as="li" onClick={() => { console.log("clicked") }}>
            <Link
                //end={route.exact}//Has something to with whether it should be active at certain urls
                //to={route.to}
                href={href}
                //state={{ open: route.to === '/authentication-modal' }}
                className='active nav-link'
                style={{ display: "inline-block" }}
            >
                <Flex alignItems="center">
                    {icon && (
                        <span className="nav-link-icon">
                            <FontAwesomeIcon icon={icon} size={iconSize} />
                        </span>
                    )}
                    <span className="nav-link-text ps-1">{title}</span>
                    {badges && badges.map((badge, index) => {
                        return (
                            <SoftBadge key={index} pill bg={badge.type} className="ms-2">
                                {badge.text}
                            </SoftBadge>
                        )
                    })}
                </Flex>
            </Link>
            {extraElement}
        </Nav.Item>
    )
}

import Link from 'next/link';
import MainLayout from 'components/MainLayout';
import { ModalContext } from "~client/DecORM/components/common/ModalProvider";
import useStores from 'hooks/useStores';
import { Store } from '~server/models';
import { useGraphQLQuery } from "~client/DecORM/utils/DataConversion";
import { GET_MY_SERVICE_CALLS } from '~client/AllGraphQLQueries';
import { IssueStatus, ServiceCall, ServiceCallStatus } from '~client/models';
import { DaysOfWeekList, convertExcelToRelativeDate } from '~server/DecORM/utils/TimeZonesEnum';
import { useServiceCalls } from 'hooks/useServiceCalls';
import { RenderIfAuthorized } from 'components/RenderIfAuthorized';
//import { faMoon } from '@fortawesome/free-regular-svg-icons';

/*const GET_OWN_TERRITORIES = gql`query{
  getOwnTerritories{
    id
    congregationID
    territoryName
    assignedToPublisherID
    checkedOutAt
    doorToDoor
  }
}`*/
//${TerritoryQueryFields}

function calculateServiceCallDays(serviceCalls: (ServiceCall & { badges?: any[] })[]) {
    let serviceCallDays: Record<number, ServiceCall[]> = {}
    for (let serviceCall of serviceCalls) {
        if (!serviceCallDays[serviceCall.day]) {
            serviceCallDays[serviceCall.day] = []
        }

        //serviceCall.badges = [{ text: serviceCall.amPM, type: serviceCall.amPM == "PM" ? "primary" : "info" }];

        serviceCall.badges = [];

        if (serviceCall.status == ServiceCallStatus.IN_PROGRESS) {
            serviceCall.badges.push({ text: <>In Progress <FontAwesomeIcon icon={faRedo} size="sm" /></>, type: "warning" })
        } else if (serviceCall.status == ServiceCallStatus.COMPLETED) {
            serviceCall.badges.push({ text: <>Completed <FontAwesomeIcon icon={faCheck} size="sm"/></>, type: "success" })
        } else if (serviceCall.status == ServiceCallStatus.CANCELLED) {
            serviceCall.badges.push({ text: <>Cancelled <FontAwesomeIcon icon={faTimes} size="sm" /></>, type: "danger" })
        } else if (serviceCall.status == ServiceCallStatus.MISSED) {
            serviceCall.badges.push({ text: "Missed", type: "danger" })
        }

        for (let issue of serviceCall?.issues || []) {
            serviceCall.badges.push({ text: issue?.issueType, type: issue?.status == IssueStatus.URGENT ? "danger" : issue?.status == IssueStatus.ATTENTION ? "warning" : "secondary" })
        }

        serviceCallDays[serviceCall.day].push(serviceCall)
    }

    for (let [key, value] of Object.entries(serviceCallDays)) {
        value.sort((a, b) => {
            if (a.amPM == "AM" && b.amPM == "PM") {
                return -1;
            }
            else if (b.amPM == "AM" && a.amPM == "PM") {
                return 1;
            }
            if (a?.store?.displayName.localeCompare(b?.store?.displayName, undefined, { numeric: true }) > 0) {
                return 1;
            }
            return -1;
        })
    }

    return serviceCallDays;
}

function convertDaysSince1970ToString(day) {

    //let now = new Date();

    //let now = new Date(new Date().toLocaleString("en-US", {timeZone: "America/New_York"}));

    //console.log(new Date().toLocaleString());

    //console.log(new Date().getTime());
    //console.log(now.getTime());

    let now = new Date();

    // console.log(now.getTimezoneOffset());

    let d = new Date(day * 1000 * 60 * 60 * 24)
    //console.log(d.getTimezoneOffset());
    //let currentDaysSince = Math.floor((now.getTime() - (now.getTimezoneOffset() * 60000)) / (1000 * 60 * 60 * 24));

    let currentDaysSince = Math.floor(new Date(now.toDateString()).getTime() / (1000 * 60 * 60 * 24));



    /*console.log("conving")
    console.log(now);
    console.log(d);
    console.log(currentDaysSince);
    console.log(day);*/



    if (day == currentDaysSince) {
        return "Today" //+ d.getUTCDate(); 
    } else if (day == currentDaysSince + 1) {
        return "Tomorrow " //+ d.getUTCDate(); 
    } else if (day >= currentDaysSince + 2 && day <= currentDaysSince + 6) {
        return DaysOfWeekList[d.getUTCDay()] //+ d.getUTCDate(); ;
    } else {

        let last = "th";
        let utcDate = d.getUTCDate();
        if (utcDate == 1 || utcDate == 21 || utcDate == 31) {
            last = "st";
        } else if (utcDate == 2 || utcDate == 22) {
            last = "nd";
        } else if (utcDate == 3 || utcDate == 23) {
            last = "rd";
        }

        return DaysOfWeekList[d.getUTCDay()] + " the " + d.getUTCDate() + last;
    }

}


const HomePage = function () {

    const [openMenu, setOpenMenu] = useState("Today");

    let authContext = useContext(AuthContext)

    console.log("auth");
    console.log(authContext);

    const modalContext = useContext(ModalContext);

    //const serviceCallsQuery = useGraphQLQuery({ query: GET_MY_SERVICE_CALLS, requestPolicy: "cache-and-network" })
    // const allServiceCalls = serviceCallsQuery.data?.getMyServiceCalls || [];

    const serviceCallPagination = useServiceCalls({ id: authContext?.tenantUser?.id });

    const allServiceCalls = serviceCallPagination.items;

    const serviceCallDays: Record<number, (ServiceCall & { badges?: any[] })[]> = useMemo(() => calculateServiceCallDays(allServiceCalls), [allServiceCalls])

    const daysWithServiceCalls = Object.keys(serviceCallDays).map((day) => parseInt(day)).sort((a, b) => a - b);

    const stores = useStores().sort((a, b) => {
        if (a.uniqueID > b.uniqueID) return -1;
        return 1
    })//.slice(0, 3);

    const [CurrentRoute, SetCurrentRoute] = useState(null);

    return (<><div className="card">
        <div className="card-header bg-light"><h5>Your Schedule</h5></div>
        <div className="card-body" style={{ padding: 0, paddingRight: 10 }}>

            <span className="BlueLink" style={{ textAlign: "center", display: "block" }} onClick={() => serviceCallPagination.loadPreviousPage()}>Load Previous...</span>

            <div className="navbar-vertical-content">
                <Nav className="flex-column" as="ul">

                    {/*<NavbarCollapseMenu title={"Show Past..."} icon={""} iconSize="sm" menu={{ openMenu, setOpenMenu }} ></NavbarCollapseMenu>*/}

                    {daysWithServiceCalls.length == 0 ? <div className="text-center" style={{ padding: 10 }}>You are not scheduled to perform any upcoming service calls.</div> : null}

                    {daysWithServiceCalls.map((day, dayIndex) => {

                        let serviceCalls = serviceCallDays[day];

                        let dayString = convertExcelToRelativeDate(day) //convertDaysSince1970ToString(day);

                        if (dayString.includes("the")) return null;

                        return (
                            <NavbarCollapseMenu key={day} liClassName={dayIndex % 2 != 0 ? "" : ""} badges={[{ text: "" + serviceCalls.length, type: "secondary" }]} title={dayString} icon={""} iconSize="sm" menu={{ openMenu, setOpenMenu }} >
                                {serviceCalls.map((serviceCall, index) => {

                                    console.log("serv call");
                                    console.log(serviceCall);
                                    console.log(serviceCall?.badges);

                                    return <div key={serviceCall?.id} className={index % 2 == 1 ? "" : ""} style={{ paddingLeft: 15, paddingRight: 10, marginLeft: -20 }}><NavbarItemLink icon={serviceCall?.amPM == "AM" ? faSun : faMoon} iconSize='sm' badges={serviceCall?.badges} key={0} title={serviceCall?.store?.displayName} href={"/serviceCall?serviceCallID=" + serviceCall?.id}

                                    /> </div>

                                })}
                            </NavbarCollapseMenu>
                        )



                    })}

                    {/*<NavbarCollapseMenu badges={[{ text: "" + 13, type: "secondary" }]} title={"Show More..."} icon={""} iconSize="sm" menu={{ openMenu, setOpenMenu }} ></NavbarCollapseMenu>*/}

                    {/*} <button className = "btn btn-secondary btn-sm" style={{marginLeft:"auto",marginRight:"auto",width:200}}>Show More...</button>*/}

                    {/*<br/>
<br/>


                    <NavbarCollapseMenu badges={[{ text: "" + stores.length, type: "secondary" }]} title="Today" icon={faCalendar} menu={{ openMenu, setOpenMenu }} >
                        {stores.map((store, index) => {

                            return <div className={index % 2 == 1 ? "bg-light" : ""} style={{ paddingLeft: 5, paddingRight: 10, marginLeft: -20 }}> <NavbarItemLink badges={[]} key={0} icon={faStore} iconSize={null} title={"#" + store.storeNumber} href={"/viewStore?storeID=" + store.id} extraElement={
                                <div style={{ float: "right" }}><button className="btn btn-primary btn-sm">Check In</button><button className="btn btn-primary btn-sm" style={{ marginLeft: 10 }}>Report Issue</button></div>}

                            /> </div>

                        })}
                    </NavbarCollapseMenu>

                    <NavbarCollapseMenu badges={[{ text: "2", type: "secondary" }]} title="Tomorrow" icon={faCalendar} menu={{ openMenu, setOpenMenu }} >

                        <NavbarItemLink badges={[]} key={0} icon={faCalendar} iconSize={true ? null : "sm"} title={"sup"} href={"/viewTerritory/" + "sup"} />

                    </NavbarCollapseMenu>

                    <NavbarCollapseMenu badges={[]} title="Wednesday" icon={faCalendar} menu={{ openMenu, setOpenMenu }} >

                        <NavbarItemLink badges={[]} key={0} icon={true ? faWalking : "envelope"} iconSize={true ? null : "sm"} title={"sup"} href={"/viewTerritory/" + "sup"} />

                    </NavbarCollapseMenu>
                    */}

                </Nav>
            </div>

            <div className="card-footer"></div>

            <span className="BlueLink" style={{ textAlign: "center", display: "block" }} onClick={() => serviceCallPagination.loadNextPage()}>Load More...</span>

        </div>
    </div>

        <RenderIfAuthorized roles={[Roles.AREA_MANAGER, Roles.ADMIN, Roles.PAYROLL, Roles.STATE_MANAGER, Roles.GENERAL_MANAGER]}>
            <div className="card">
                <div className="card-header bg-light"><h5>Manager</h5></div>
                <div className="card-body" style={{ padding: 0, paddingRight: 10 }}>

                    <div className="navbar-vertical-content">
                        <Nav className="flex-column" as="ul">

                            <NavbarCollapseMenu badges={[{ text: "123", type: "secondary" }]} title="All Service Calls" iconSize='lg' icon={faShippingFast} menu={{ openMenu, setOpenMenu }} >
                                <p>TODO: Add list of service calls managed by logged in user</p>
                            </NavbarCollapseMenu>

                            <NavbarCollapseMenu badges={[{ text: "123", type: "secondary" }]} title="Issues" icon={faDumpsterFire} iconSize='lg' menu={{ openMenu, setOpenMenu }} >
                                <p>TODO: Add list of issues managed by the logged in user</p>
                            </NavbarCollapseMenu>


                            {/*} <NavbarCollapseMenu badges={[{ text: "" + stores.length, type: "secondary" }]} iconSize='lg' title="All Stores" icon={faStore} menu={{ openMenu, setOpenMenu }} >
                                {stores.map((store, index) => {

                                    let badges = [];
                                    if (store.weeklySchedule.includes("UNSCHEDULED")){
                                        badges.push({ text: "Unassigned Days", type: "danger" })
                                    }

                                    return <div key={store.id} className={index % 2 == 1 ? "" : ""} style={{ paddingLeft: 5, paddingRight: 10, marginLeft: -20 }}> <NavbarItemLink badges={badges} key={index} icon={""} title={store?.displayName} href={"/viewStore?id=" + store?.id}

                                    /> </div>

                                })}
                            </NavbarCollapseMenu>*/}

                            <RenderIfAuthorized resolvers={[Resolvers.VIEW_ALL_ACTIVE_EMPLOYEES, Resolvers.VIEW_ALL_EMPLOYEES]}>
                                <NavbarItemLink title={"Employees"} iconSize='lg' icon={faUsers} href={"/viewEmployees"} />
                            </RenderIfAuthorized>

                            <RenderIfAuthorized resolver={Resolvers.VIEW_ALL_STORES}>
                                <NavbarItemLink title={"Stores"} iconSize='lg' icon={faStore} href={"/viewStores"} />
                            </RenderIfAuthorized>

                            <RenderIfAuthorized resolver={Resolvers.VIEW_PAYROLL_PAGE}>
                                <NavbarItemLink title={"Payroll"} iconSize='lg' icon={faMoneyCheckAlt} href={"/payRoll"} />
                            </RenderIfAuthorized>

                            <RenderIfAuthorized resolver={Resolvers.VIEW_ALL_ACTIVE_EMPLOYEES}>
                                <RenderIfAuthorized resolver={Resolvers.VIEW_ALL_STORES}>
                                    <NavbarItemLink title={"View Map"} iconSize='lg' icon={faMap} href={"/viewMap"} />
                                </RenderIfAuthorized>
                            </RenderIfAuthorized>



                        </Nav>
                    </div>
                </div>
                <div className="card-footer">
                    {/*<button onClick={()=>{
                        serviceCallsQuery.reExecuteQuery();
                        console.log("re executing");
                    }} >Reexecute Query</button>*/}

                </div>
            </div>
        </RenderIfAuthorized>






    </>)



};

HomePage.getLayout = function (page) {
    return (
        <MainLayout authenticationRequired={true}>
            {page}
        </MainLayout>
    )
}

export default HomePage;
