import { ServiceCall } from "~client/models";
import {AuthContext} from "~client/context/Auth";
import { useContext, useEffect, useState } from "react";
import { gql, useClient, useQuery } from "urql";

import { GET_PAGINATED_SERVICE_CALLS, GET_SERVICE_CALLS, GET_SERVICE_CALL_BY_ID } from "~client/AllGraphQLQueries";
import { deserializeFromGraphQL, executeGraphQLMutation } from "~client/DecORM/utils/DataConversion";
import { getLocalExcelDate } from "~client/DecORM/utils/TimeZonesEnum";

interface PaginationResult<t>{
 /* constructor({start, end, pageSize, loadNextPage, loadPreviousPage} : {start?:number, end?:number, pageSize?:number,
  loadNextPage:()=>void, loadPreviousPage:()=>void}){
    let today = getLocalExcelDate();
    this.pageSize = pageSize || 7;
    this.start = start || today;
    this.end = end || today + this.pageSize;
  }*/
start: number;
end: number;

pageSize: number;

hasPreviousPage: boolean;
hasNextPage: boolean;

loadNextPage: ()=>void;
loadPreviousPage: ()=>void;

items: t[];


}

/**Returns a service call by it's ID.
 * 
 * @param id store ID or employeeID. If undefined uses the current user's employeeID.
 * @param startDate 
 * @param endDate
 * @returns ServiceCall or null if not found or undefined if loading
 */
export function useServiceCalls({id, startDate, endDate, pause=false}:{id?:string, startDate?:number, endDate?:number, pause?:boolean}):PaginationResult<ServiceCall> | null{

  const auth = useContext(AuthContext);

  const today = getLocalExcelDate();
  const client = useClient()

    const [paginationData, setPaginationData] = useState<PaginationResult<ServiceCall>>({

      start: today,
      end: today + 6,
      pageSize: 7,
      hasPreviousPage: true,
      hasNextPage: true,
      items: [],

      loadNextPage: ()=>{
        executeGraphQLMutation({
          query: GET_PAGINATED_SERVICE_CALLS,
          variables: {
              id,
              startDate: (paginationData.end + 1) + paginationData.pageSize,
              endDate: (paginationData.end + paginationData.pageSize) + paginationData.pageSize,
          },
          client
      });
      setPaginationData({
          ...paginationData,
          end: paginationData.end + paginationData.pageSize,
      });
      },

      loadPreviousPage: ()=>{
        executeGraphQLMutation({
          query: GET_PAGINATED_SERVICE_CALLS,
          variables: {
              id,
              startDate: (paginationData.start - paginationData.pageSize) - paginationData.pageSize,
              endDate: (paginationData.start - 1) - paginationData.pageSize,
          },
          client
      });

      setPaginationData({
          ...paginationData,
          start: paginationData.start - paginationData.pageSize,
      });
      }
    });

  console.log(auth);

  //let pause = false;

  if (!auth.isAuthInitialized){
      pause = true;
  }

  if (!id){
    pause = true;
  }

  let vars = {};
  if (id){
    vars["id"] = id;
  }
  if (startDate){
    //vars["startDate"] = startDate;
  }
  if (endDate){
  //  vars["endDate"] = endDate;
  }


  const [result] = useQuery({ query: GET_SERVICE_CALLS, pause, variables:vars, requestPolicy:"cache-and-network"  }, )


  console.log("service call pre filter result");
  console.log(result);

  let serviceCalls : ServiceCall[] = result.data?.getServiceCalls || [];

  let outputServiceCalls = [];

  for (let serviceCall of serviceCalls){
    if (serviceCall.day >= paginationData.start && serviceCall.day <= paginationData.end){
      outputServiceCalls.push(serviceCall);
    }
  }

  outputServiceCalls.sort((a, b) => {
    if (a.day > b.day) return 1;
    if (a.day < b.day) return -1;
    if (a.amPM == "AM" && b.amPM != "AM") return -1;
    if (b.amPM == "AM" && a.amPM != "AM") return 1;
    return 0;
  });

  console.log("service call post filter result");
  console.log(outputServiceCalls);

  outputServiceCalls = deserializeFromGraphQL(outputServiceCalls);

  paginationData.items = outputServiceCalls;

  console.log("PAGINATION DATA");
  console.log(paginationData);

  paginationData.loadPreviousPage= ()=>{
    executeGraphQLMutation({
      query: GET_PAGINATED_SERVICE_CALLS,
      variables: {
          id,
          startDate: (paginationData.start - paginationData.pageSize) - paginationData.pageSize,
          endDate: (paginationData.start - 1) - paginationData.pageSize,
      },
      client
  });
  setPaginationData({
      ...paginationData,
      start: paginationData.start - paginationData.pageSize,
  });
  }

  paginationData.loadNextPage=()=>{
    executeGraphQLMutation({
      query: GET_PAGINATED_SERVICE_CALLS,
      variables: {
          id,
          startDate: (paginationData.end + 1) + paginationData.pageSize,
          endDate: (paginationData.end + paginationData.pageSize) + paginationData.pageSize,
      },
      client
  });
  setPaginationData({
      ...paginationData,
      end: paginationData.end + paginationData.pageSize,
  });
  }

  return paginationData;

}